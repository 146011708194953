import Home from './pages/Home';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./bootstrap.css"
import 'boxicons/css/boxicons.css'
import { Routes, Route, BrowserRouter, HashRouter } from 'react-router-dom';
import Login from './pages/Login';
import State from './pages/state/State';
import StateAdd from './pages/state/StateAdd';
import StateEdit from './pages/state/StateEdit';
import District from './pages/District/District';
import DistrictAdd from './pages/District/DistrictAdd';
import DistrictEdit from './pages/District/DistrictEdit';
import Bank from './pages/Bank/Bank';
import BankAdd from './pages/Bank/BankAdd';
import BankEdit from './pages/Bank/BankEdit';
import BankDetail from './pages/Bank-details/BankDetail';
import BankDetailAdd from './pages/Bank-details/BankDetailAdd';
import BankDetailEdit from './pages/Bank-details/BankDetailEdit';
import Branch from './pages/Branch/Branch';
import BranchAdd from './pages/Branch/BranchAdd';
import BranchEdit from './pages/Branch/BranchEdit';
import Holiday from './pages/Holiday/Holiday';
import HolidayAdd from './pages/Holiday/HolidayAdd';
import HolidayEdit from './pages/Holiday/HolidayEdit';
import Loan from './pages/Loan/Loan';
import LoanAdd from './pages/Loan/LoanAdd';
import LoanEdit from './pages/Loan/LoanEdit';
import LoanDetail from './pages/LoanDetail/LoanDetail';
import LoanDetailAdd from './pages/LoanDetail/LoanDetailAdd';
import LoanDetailEdit from './pages/LoanDetail/LoanDetailEdit';
import MutualFund from './pages/MutualFund/MutualFund';
import MutualFundAdd from './pages/MutualFund/MutualFundAdd';
import MutualFundEdit from './pages/MutualFund/MutualFundEdit';
import MutualFundDetail from './pages/MutualFundDetail/MutualFundDetail';
import MutualFundDetailAdd from './pages/MutualFundDetail/MutualFundDetailAdd';
import MutualFundDetailEdit from './pages/MutualFundDetail/MutualFundDetailEdit';
import AMC from './pages/Amc/Amc';
import AMCAdd from './pages/Amc/AmcAdd'
import AMCEdit from './pages/Amc/AmcEdit'
import AMCDetail from './pages/AmcDetail/AmcDetail'
import AMCDetailAdd from './pages/AmcDetail/AmcDetailAdd'
import AMCDetailEdit from './pages/AmcDetail/AmcDetailEdit'
import MutualFundCompany from './pages/MutualFundCompany/MutualFundCompany'
import MutualFundCompanyAdd from './pages/MutualFundCompany/MutualFundCompanyAdd'
import MutualFundCompanyEdit from './pages/MutualFundCompany/MutualFundCompanyEdit'
import TopFundDetail from './pages/TopFundDetail/TopFundDetail'
import TopFundDetailAdd from './pages/TopFundDetail/TopFundDetailAdd'
import TopFundDetailEdit from './pages/TopFundDetail/TopFundDetailEdit'
import Version from './pages/Version/Version'
import VersionAdd from './pages/Version/VersionAdd'
import VersionEdit from './pages/Version/VersionEdit'

import PublicPF from "./pages/Webview/PublicPF";
import EmployeePF from "./pages/Webview/EmployeePF";
import NationalPS from "./pages/Webview/NationalPS";
import SeniorCS from "./pages/Webview/SeniorCS";
import NationalSC from "./pages/Webview/NationalSC";
import PostOfficeSS from "./pages/Webview/PostOfficeSS";
import PMVaya from "./pages/Webview/PMVaya";
import PMJanDhan from "./pages/Webview/PMJanDhan";
import axios from 'axios'
import PersonalLoan from './pages/Webview/PersonalLoan';
import CarLoan from './pages/Webview/CarLoan';
import HomeLoan from './pages/Webview/HomeLoan';
import AmcAbout from './pages/Webview/AmcAbout';
import AmcInvest from './pages/Webview/AmcInvest';
import AmcTopfundMan from './pages/Webview/AmcTopfundMan';
import Fuel from './pages/Fuel/Fuel';
import FuelAdd from './pages/Fuel/FuelAdd';
import FuelEdit from './pages/Fuel/FuelEdit';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import './App.css';
import './utilities.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createContext, useState } from 'react';
export const API = axios.create({baseURL:process.env.REACT_APP_NODEURL})

export const AuthContext = createContext();

function App() {
  API.defaults.headers.nodeTok = process.env.REACT_APP_APIKEY
  const [stSearch,setStSearch] = useState("")
  const [dtSearch,setDtSearch] = useState({state:"",city:""})
  const [bankSearch,setBankSearch] = useState("")
  const [bankDtSearch,setBankDtSearch] = useState("")
  const [holidaySearch,setHolidaySearch] = useState("")
  const [loanSearch,setLoanSearch] = useState("")
  const [loanDetSearch,setLoanDetSearch] = useState("")
  const [mutualSearch,setMutualSearch] = useState("")
  const [mutualDetSearch,setMutualDetSearch] = useState("")
  const [amcDetSearch,setAmcDetSearch] = useState("")
  const [mfcSearch,setMfcDetSearch] = useState("")
  const [topFundSearch,setTopFundSearch] = useState("")
  const [versionSearch,setVersionSearch] = useState("")
  const [fuelSearch, setFuelSearch] = useState({state: "",city: "",date: ""})
  const [branchSearch, setBranchSearch] = useState({bank:"",state:"",city:""})
  const [commonSearch,setCommonSearch] = useState([
    {
      name:"State", //0
      search:""
    },
    {
      name:"City", //1
      search:{
        state:"",
        city:""
      }
    },
    {
      name:"Bank", //2
      search:""
    },
    {
      name:"Bank Detail", //3
      search:""
    },
    {
      name:"Holiday", //4
      search:""
    },
    {
      name:"Loan", //5
      search:""
    },
    {
      name:"Loan Detail", //6
      search:""
    },
    {
      name:"Mutual", //7
      search:""
    },
    {
      name:"Mutual Detail", //8
      search:""
    },
    {
      name:"Amc Detail", //9
      search:""
    },
    {
      name:"MFC", //10
      search:""
    },
    {
      name:"Top Fund", //11
      search:""
    },
    {
      name:"Version", //12
      search:""
    },
    {
      name:"Fuel", //13
      search:{
        state:"",
        city:"",
        date:""
      }
    },
    {
      name:'Branch', //14
      search:{
        bank:"",
        state:"",
        city:""
      }
    }
  ])
  return (
    <>

      <AuthContext.Provider  value={{stSearch,setStSearch,dtSearch,setDtSearch,bankSearch,setBankSearch,bankDtSearch,setBankDtSearch,
      holidaySearch,setHolidaySearch,loanSearch,setLoanSearch,loanDetSearch,setLoanDetSearch,mutualSearch,setMutualSearch,
      mutualDetSearch,setMutualDetSearch,amcDetSearch,setAmcDetSearch,mfcSearch,setMfcDetSearch,topFundSearch,setTopFundSearch,
      versionSearch,setVersionSearch,fuelSearch, setFuelSearch,branchSearch, setBranchSearch,commonSearch,setCommonSearch}}>
      <BrowserRouter>
        <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/terms-of-use" element={<TermsOfUse/>} />
          <Route path="/home" element={<Home/>} />
          {/* <Route path="/Images" element={<Images/>} />
          <Route path="/ImagesAdd" element={<ImagesAdd/>} /> */}
          <Route path="/" element={<Login/>} />
          <Route path="/state" element={<State/>} />
          <Route path="/state/add" element={<StateAdd/>} />
          <Route path="/state/edit/:_id" element={<StateEdit/>} />
          <Route path="/district" element={<District/>} />
          <Route path="/district/add" element={<DistrictAdd/>} />
          <Route path="/district/edit/:_id" element={<DistrictEdit/>} />
          <Route path="/bank" element={<Bank/>} />
          <Route path="/bank/add" element={<BankAdd/>} />
          <Route path="/bank/edit/:_id" element={<BankEdit/>} />
          <Route path="/bank-detail" element={<BankDetail/>} />
          <Route path="/bank-detail/add" element={<BankDetailAdd/>} />
          <Route path="/bank-detail/edit/:_id" element={<BankDetailEdit/>} />
          <Route path="/branch" element={<Branch/>} />
          <Route path="/branch/add" element={<BranchAdd/>} />
          <Route path="/branch/edit/:_id" element={<BranchEdit/>} />
          <Route path="/holiday" element={<Holiday/>} />
          <Route path="/holiday/add" element={<HolidayAdd/>} />
          <Route path="/holiday/edit/:_id" element={<HolidayEdit/>} />
          <Route path="/loan" element={<Loan/>} />
          <Route path="/loan/add" element={<LoanAdd/>} />
          <Route path="/loan/edit/:_id" element={<LoanEdit/>} />
          <Route path="/loandetail" element={<LoanDetail/>} />
          <Route path="/loandetail/add" element={<LoanDetailAdd/>} />
          <Route path="/loandetail/edit/:_id" element={<LoanDetailEdit/>} />
          <Route path="/mutualfund" element={<MutualFund/>} />
          <Route path="/mutualfund/add" element={<MutualFundAdd/>} />
          <Route path="/mutualfund/edit/:_id" element={<MutualFundEdit/>} />
          <Route path="/mutualfunddetail" element={<MutualFundDetail/>} />
          <Route path="/mutualfunddetail/add" element={<MutualFundDetailAdd/>} />
          <Route path="/mutualfunddetail/edit/:_id" element={<MutualFundDetailEdit/>} />
          <Route path="/amc" element={<AMC/>} />
          <Route path="/amc/add" element={<AMCAdd/>} />
          <Route path="/amc/edit/:_id" element={<AMCEdit/>} />
          <Route path="/amcdetail" element={<AMCDetail/>} />
          <Route path="/amcdetail/add" element={<AMCDetailAdd/>} />
          <Route path="/amcdetail/edit/:_id" element={<AMCDetailEdit/>} />
          <Route path="/mutualfundcompany" element={<MutualFundCompany/>} />
          <Route path="/mutualfundcompany/add" element={<MutualFundCompanyAdd/>} />
          <Route path="/mutualfundcompany/edit/:_id" element={<MutualFundCompanyEdit/>} />
          <Route path='/funddetail' element={<TopFundDetail/>}/>
          <Route path='/funddetail/add' element={<TopFundDetailAdd/>}/>
          <Route path='/funddetail/edit/:_id' element={<TopFundDetailEdit/>} />

          <Route path="Webview/PublicPF.jsx" element={<PublicPF/>} />
          <Route path="Webview/EmployeePF.jsx" element={<EmployeePF/>} />
          <Route path="Webview/NationalPS.jsx" element={<NationalPS/>} />
          <Route path="Webview/SeniorCS.jsx" element={<SeniorCS/>} />
          <Route path="Webview/NationalSC.jsx" element={<NationalSC/>} />
          <Route path="Webview/PostOfficeSS.jsx" element={<PostOfficeSS/>} />
          <Route path="Webview/PMVaya.jsx" element={<PMVaya/>} />
          <Route path="Webview/PMJanDhan.jsx" element={<PMJanDhan/>} />

          <Route path="Webview/PersonalLoan" element={<PersonalLoan/>} />
          <Route path="Webview/CarLoan" element={<CarLoan/>} />
          <Route path="Webview/HomeLoan" element={<HomeLoan/>} />

          <Route path="Webview/AmcAbout/:_id" element={<AmcAbout/>} />
          <Route path="Webview/AmcInvest/:_id" element={<AmcInvest/>} />
          <Route path="Webview/AmcTopFundMan/:_id" element={<AmcTopfundMan/>} />

          <Route path="/fuel" element={<Fuel/>} />
          <Route path="/fuel/add" element={<FuelAdd/>} />
          <Route path="/fuel/edit/:_id" element={<FuelEdit/>} />
          <Route path='/version' element={<Version/>} />
          <Route path="/version/add" element={<VersionAdd/>} />
          <Route path="/version/edit/:_id" element={<VersionEdit/>} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position='bottom-right' autoClose={500} /> 
      </AuthContext.Provider>
    </>
  );
}

export default App;
