import React from 'react'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { API } from '../App';
import { Card } from 'react-bootstrap';
import { useEffect } from 'react';
import { useState } from 'react';
import base64 from 'base-64'
import {toast} from 'react-toastify'
import Cookies from 'js-cookie'
// import { Link } from 'react-router-dom';




const Login = () => {

  const tokenAuth = async () => {
    const ejwt = localStorage.getItem('ejwt')
    const pjwt = localStorage.getItem('pjwt')
    if (ejwt && pjwt) {
      const mail = base64.decode(ejwt)
      const pwd = base64.decode(pjwt)
      inpData.email = mail
      inpData.password = pwd
    }
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === true) {
      navigate("/home")
    }
  }

  const navigate = useNavigate()

  const loginData = async (event) => {
    event.preventDefault()
    if (inpData.email === "") {
      toast.error('Please Enter Email-Id')
    } else if (inpData.password === "") {
      toast.error('Please Enter Password')
    } else {

      const userData = {
        email: inpData.email,
        password: inpData.password
      }
      const response = await API.post("/login", userData)
      if (response.data.status === true) {
        const emailEnc = base64.encode(inpData.email)
        const passEnc = base64.encode(inpData.password)
        if (inpData.remember === true) {
          localStorage.setItem('ejwt', emailEnc)
          localStorage.setItem('pjwt', passEnc)
        } else {
          localStorage.removeItem('ejwt')
          localStorage.removeItem('pjwt')
        }
        toast.success('Login Succesfull')
        // sessionStorage.setItem('jwt', response.data.data.token)
        Cookies.set('jwt', response.data.data.token, { expires: 1 })
        navigate("/home")

      } else{
        if(response.data.response_code===429){
          toast.error("Please enter valid API key")
        }else{
          toast.error("Invalid Email or Password")
        }
      }
    }
  }

  const [inpData, setInpData] = useState({
    email: (localStorage.getItem('ejwt')) ? base64.decode(localStorage.getItem('ejwt')) : "",
    password: (localStorage.getItem('pjwt')) ? base64.decode(localStorage.getItem('pjwt')) : "",
    remember: (localStorage.getItem('ejwt') && localStorage.getItem('pjwt')) ? true : false
  })

  const getValue = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value })
  }

  const Remember = (e) => {
    setInpData({ email: inpData.email, password: inpData.password, remember: e.target.checked })
  }

  // const [remember, setRemember] = useState(false)

  useEffect(() => {
    tokenAuth()
  },[])


  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
    }
    else {
      setpassword("password");
      seteye(true);
    }
  }
  return (
    <>
      <Container>
        <div className="auth">
          <div className="auth-box">
            <Card>
              <Card.Header className="pb-0">
                <div className="auth-logo">
                  <img src="../logo/app-Icon.svg" alt="SparkIQ" className='logo-mini' />
                </div>
              </Card.Header>
              <Card.Body>
                <Form>
                  <h1 className="auth-title">Sign in</h1>
                  <h2 className="auth-subtitle">Bank Balance Check Statement</h2>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" className="my-2" name='email' value={inpData.email} onChange={(e) => { getValue(e) }} placeholder="Enter Email" />
                  </Form.Group>

                  <Form.Group className="mb-4 input-prefix" >
                    <Form.Label>Password</Form.Label>
                    <Form.Control type={password} className="my-2" name='password' value={inpData.password} onChange={(e) => { getValue(e) }} placeholder="Enter Password" />
                    <i onClick={Eye} className={`bx ${eye ? "bx-hide" : "bx-show"}`}></i>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Check type="checkbox" name="" id="" label="Remember Me" checked={inpData.remember} onClick={(e) => { Remember(e) }} />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100" onClick={loginData}>
                    Sign In
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Login