import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

const TermsOfUse = () => {
	return (
		<Container>
			<Row className='justify-content-center'>
				<Col xxl={10} xl={11} xs={12}>
					<div className='sparkiq-privacy'>
					<h1>Terms and Conditions</h1>
					<h2>Bank Balance Check Statement</h2>


					<h3>Terms and Conditions</h3>

					<p>his policy covers all apps published by <b>SparkIQ</b>. It explains how we collect, use, and share information from users of our apps, including <b>Bank Balance Check Statement</b>. We take your privacy seriously and are committed to protecting your information.</p>

					<p>This policy clarifies:</p>

					<ul>
						<li>What data we collect from you</li>
						<li>How we use that data to improve your experience</li>
						<li>How we keep your information secure</li>
					</ul>

					<p>By using our apps, you agree to the terms of this Terms and Conditions.</p>

					<h3> What information do we collect?</h3>

					<h5>[A] What you provide:</h5>

					<p>When you create an account, subscribe to a service, or interact with the app, we collect information you give us directly, such as your name, email, and any other details you choose to share.</p>

					<p>We may also collect limited payment details, like the type of card you use, but we don't store your full credit card information (that's handled securely by our payment processor).</p>

					<h5>[B] What we collect automatically:</h5>

					<p>When you use the app, we automatically gather some information about your device and activity. This includes things like your device type, operating system, and what features you use.</p>

					<p>Location data and access to certain apps on your device (like camera or photo album) might be collected to enhance specific features, but you'll find details on how to manage this in the "Your Choices" section.</p>

					<p>Cookies and similar tracking technologies help us understand how you use the app and improve your experience. We'll explain more about these and how to disable them in "Your Choices."</p>

					<h5>[C] Information from other sources:</h5>

					<p>We may also receive information about you from other sources, like social media, if you connect your accounts.</p>

					<p>Link to the Terms and Conditions of third-party service providers used by the app</p>
					<ul>
						<li>Google Play Services</li>
						<li>AdMob</li>
						<li>Google Analytics for Firebase</li>
						<li>Firebase Crashlytics</li>
					</ul>


					<h3>Permission We Use:</h3> 

					<p><b>READ_SMS:</b> Permission to check SMS messages after a call allows the app to check the balance and display the amount on the app's screen.</p>

					<p><b>RECEIVE SMS:</b> Permission is required in the app to receive data from new transaction SMS received on the user's device.</p>

					<h3> Use of Information that we collect:</h3>

					<p>We use the information you provide and data about your app usage to improve the Bank Balance Check Statement app, fix issues, send you important information about the app, and occasionally keep you informed about interesting features, deals, or events. We may also use this information to personalize your experience within the app and show you relevant ads. We take steps to protect your information and only share it with trusted partners who help us run the app.</p>
					
					<h3>Log Data</h3>

					<p>I want to inform you that whenever you use my Service, in a case of an error in the app I collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.</p>

					<h3>Cookies</h3>

					<p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>

					<p>This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>

					<h3> Keeping Your Information Safe - Security</h3>

					<p>We take protecting your information seriously. We use strong security measures to prevent unauthorized access, use, or disclosure of your data. However, no security system is perfect, and we can't guarantee the complete safety of any information you send over the internet.</p>

					<p>This means it's important for you to be responsible too. Keep your password confidential and don't share it with anyone.</p>

					<h5>[A] Sharing Your Information</h5>

					<p>We do not share your information with any third parties for marketing or advertising purposes. We may share your information with third-party service providers, This may include companies that help with technical aspects, marketing, or research. These service providers are contractually obligated to keep your information confidential and secure. We will never share your information for advertising purposes without your permission.</p>

					<h5>[B] Understanding Your App Usage and Seeing Relevant Ads</h5>

					<p>We work with other companies to help us understand how you use the Bank Balance Check Statement app and show you ads that might be interesting to you. These companies use things like cookies and website trackers to collect information about your activity on the app and other websites. We never share your personal information for advertising purposes.</p>

					<p>You can choose to turn off personalized ads in the app settings. This means you'll still see ads, but they won't be tailored to your interests.</p>

					<h3> Children's Privacy</h3>

					<p>The App is not directed to children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and you believe that your child has provided us with personal information, please get in touch with us <b>vipulsudani48@gmail.com</b>. If we become aware that we have collected personal information from a child under the age of 13, we will take steps to delete that information.</p>

					<h3> Links to Other Sites</h3>

					<p>The App may contain links to other websites. We are not responsible for the privacy practices of other websites. We encourage you to review the Terms and Conditions of any website you visit.</p>

					<h3> Changes to this Terms and Conditions</h3>

					<p>We may update this Terms and Conditions from time to time. We will notify you of any changes by posting the new Terms and Conditions on the App.</p>

					<h3> Contact Us</h3>

					<p>If you have any questions about this Terms and Conditions or application,</p>

					<p>Please get in touch with us at </p>
					<p className='mb-2'><b>SparkIQ</b></p>
					<span><b><a href='mailto:vipulsudani48@gmail.com' target="_blank">vipulsudani48@gmail.com</a></b></span>
					</div>
				</Col>
			</Row>
		</Container>
	)
}

export default TermsOfUse