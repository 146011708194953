import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Dropdown, Button } from 'react-bootstrap'
import { API } from "../App";
import LogoMini from '../Component/Logo-mini';
import DarkMode from "../Component/DarkMode";
import {toast} from 'react-toastify'
import Cookies from 'js-cookie';

const Header = ({ openSidebar }) => {
    const navigate = useNavigate()

    const logoutFunc = async () => {
        const token = Cookies.get('jwt')
        if (token) {
            const response = await API.post("/logout", {}, { headers: { "Authorization": `Bearer ${token}` } })
            toast('Logout Succesfully')
            Cookies.remove('jwt')
            navigate("/")
        } else {
            navigate("/")
        }
    }
    return (
        <>
            <header className="sidebar-header">
                <div className="header-left-menu">
                    <Link to="/" className='d-xl-none'>
                        <LogoMini />
                    </Link>
                </div>
                <div className="header-right-menu">
                    <DarkMode />
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-autoclose-true">
                            <div className="user-menu">
                                <div className="user-img">
                                    <img src="../logo/app-Icon.svg" alt="SparkIQ" className='auth-logo' />
                                </div>
                                <div className="user-name ms-2">
                                    <h6>Bank Balance Check Statement</h6>
                                    <p>Admin</p>
                                </div>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                            <Dropdown.Item href="#" onClick={logoutFunc} >Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button className="burger-btn d-xl-none" onClick={openSidebar}>
                        <i className='bx bx-menu fs-3'></i>
                    </Button>
                </div>
            </header>
        </>
    )
}

export default Header